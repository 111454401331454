import api from 'store/services/api';

import { setPetitionNotFound } from './modal';

export const GETGROUPTYPES = 'request/GETGROUPTYPES';
export const GETDAYS = 'request/GETDAYS';
export const GETHOURS = 'request/GETHOURS';
export const SETDATETIME = 'request/SETDATETIME';
export const SETREQUEST = 'request/SETREQUEST';

const initialState = {
  groupTypes: undefined,
  actualRequest: undefined,
  dateTime: undefined,
  schudule: undefined,
  days: undefined,
  fullDays: undefined,
  languages: [
    { id: 'es', title: 'lang_es' },
    // { id: 'ca', title: 'lang_ca' },
    { id: 'en', title: 'lang_en' },
  ],
};

const request = (state = initialState, action) => {
  switch (action.type) {
    case GETGROUPTYPES:
      return {
        ...state,
        groupTypes: action.playload,
      };
    case GETDAYS:
      return {
        ...state,
        days: action.playload.availableDays,
        fullDays: action.playload.fullDays,
      };
    case GETHOURS:
      return {
        ...state,
        schudule: action.playload,
      };
    case SETDATETIME:
      return {
        ...state,
        dateTime: action.playload,
      };
    case SETREQUEST:
      return {
        ...state,
        dateTime: undefined,
        actualRequest: action.playload,
      };
    default:
      return state;
  }
};

export default request;

export const getDays = (
  building = undefined,
  start_date = undefined,
  end_date = undefined,
) => {
  return dispatch => {
    Promise.all([
      api.get(dispatch, 'available-days', building, { start_date, end_date }),
      api.get(dispatch, 'full-days', building, { start_date, end_date }),
    ]).then(([availableDays, fullDays]) => {
      dispatch({
        type: GETDAYS,
        playload: {
          availableDays,
          fullDays,
        },
      });
    });
  };
};

export const getHours = (building = undefined, day = undefined) => {
  return dispatch => {
    if (day)
      api.get(dispatch, 'available-hours', building, { date: day }).then(r => {
        dispatch({
          type: GETHOURS,
          playload: { day, hours: r },
        });
        dispatch({
          type: SETDATETIME,
          playload: undefined,
        });
      });
    else {
      dispatch({
        type: GETHOURS,
        playload: undefined,
      });
      dispatch({
        type: SETDATETIME,
        playload: undefined,
      });
    }
  };
};

export const getGroupTypes = locale => {
  return dispatch => {
    api.get(dispatch, locale + '/group-types').then(r => {
      dispatch({
        type: GETGROUPTYPES,
        playload: r,
      });
    });
  };
};

export const setDateTime = (dateTime = undefined) => {
  const playload = dateTime;
  return dispatch => {
    dispatch({
      type: SETDATETIME,
      playload,
    });
  };
};

export const setRequest = (request = undefined) => {
  return dispatch => {
    if (request !== undefined) {
      api.post(dispatch, 'petitions', request).then(r => {
        dispatch({
          type: SETREQUEST,
          playload: r,
        });
      });
    } else {
      dispatch({
        type: SETREQUEST,
        playload: initialState.actualRequest,
      });
    }
  };
};

export const cancelRequest = (id, email) => {
  return dispatch => {
    api.delete(dispatch, 'petitions/' + id + '/' + email).then(r => {
      if (typeof r.status === 'number') {
        if (r.status === 404) {
          setPetitionNotFound(dispatch);
        } else {
          console.log('error', r.status, r.statusText);
        }
      } else {
        dispatch({
          type: SETREQUEST,
          playload: r,
        });
      }
    });
  };
};

export const getLanguage = id => {
  let language = initialState.languages.find(l => l.id === id);
  if (language) {
    return dispatch => language.title;
  } else {
    return dispatch => id;
  }
};
