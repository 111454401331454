import React from 'react'
import {Provider} from 'react-redux'

import createStore from './src/store/index';

import './src/utils/i18n';

const WrapWithProvider = ({element}) => {
    const store = createStore();

    return <Provider store={store}>{element}</Provider>
}

export default WrapWithProvider
